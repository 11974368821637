import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useStyles } from './subscription-price-section.styles'
import { usePlanChangeFlag } from '../../../../hooks/usePlanChange'
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag'
import BasicPlanDiscountImage from '../../../../../images/neon/basic-plan-discount.svg'
import StandardPlanDiscountImage from '../../../../../images/neon/standard-plan-discount.svg'
import { basicPlanDetailsWithDiscount, standardPlanDetailsWithDiscount, planType } from '../../../shared/subscription-constants'

const CURRENT_PLAN_TITLE = 'CURRENT PLAN'

const SubscriptionPriceSection = props => {
  const styles = useStyles()
  const {
    title, label, price, period, subTitle, activeSubscription, updateActiveSubscription, currentSubscription
  } = props
  const index = price.indexOf('.')
  const priceBeforeDot = price.substr(0, index)
  const priceAfterDot = price.substr(index)
  const getPlanDetails = usePlanChangeFlag()
  const show2023BasicDiscount = useFeatureFlag('2023_Basic_Discount')
  const show2024StandardDiscount = useFeatureFlag('2024_Standard_Discount')

  const planHeader = () => {
    let content = null
    if (!currentSubscription && title?.toUpperCase() === planType.ANNUAL) {
      // Only show annual extra info in header if user do not have any subscription
      content = getPlanDetails?.annual.content
    } else if (currentSubscription === title) {
      content = CURRENT_PLAN_TITLE
    }
    return <div className={styles.planTitle}>{content}</div>
  }

  const planFooter = () => {
    if (title === getPlanDetails?.annual.title) {
      return <div className={classNames(styles.planTitle, styles.planFooterTitle)}>{getPlanDetails?.annual.content}</div>
    }
    return null
  }

  const priceSection = () => {
    return (
      <div className={classNames(styles.priceContent, currentSubscription === title && styles.priceContentDisabled)}>
        <div className={classNames(styles.priceAmount, activeSubscription === title && styles.activeSubscription, currentSubscription === title && styles.priceAmountDisabled)}>
          <span className={styles.currency}>$</span>
          <span className={styles.priceAmountBeforeDot}>{priceBeforeDot}</span>
          <span className={styles.priceAmountAfterDot}>{priceAfterDot}</span>
        </div>
        <span className={styles.priceContentPeriod}>{period}</span>
        <span className={styles.priceContentSubTitle}>{subTitle}</span>

        {currentSubscription && planFooter()}
      </div>
    )
  }

  const priceSectionWithDiscount = (planDetailsWithDiscount, discountImage) => {
    return (
      <div className={styles.priceContent}>
        <div className={classNames(styles.priceAmount, activeSubscription === title && styles.activeSubscription, currentSubscription === title && styles.priceAmountDisabled)}>
          <span className={styles.currency}>$</span>
          <span className={styles.priceAmountBeforeDot}>{planDetailsWithDiscount.priceBeforeDot}</span>
          <span className={styles.priceAmountAfterDot}>{planDetailsWithDiscount.priceAfterDot}</span>
        </div>
        <img className={styles.discountImage} src={discountImage} />
        <span className={styles.priceContentPeriod}>{planDetailsWithDiscount.periodLine}</span>
        <div className={styles.discountLabel}>
          <p className={styles.discountLabelText}>
            {planDetailsWithDiscount.label}
          </p>
        </div>
        <span className={styles.discountEndDate}>{planDetailsWithDiscount.endDate}</span>
        <a
          className={styles.discountTermsAndConditions}
          href={planDetailsWithDiscount.termsAndConditionsLink}
          target={planDetailsWithDiscount.termsAndConditionsLinkTarget}
          rel={planDetailsWithDiscount.termsAndConditionsLinkRef}
        >
          {planDetailsWithDiscount.termsAndConditions}
        </a>
      </div>
    )
  }

  const renderPriceSection = () => {
    if (show2023BasicDiscount && title?.toUpperCase() === planType.BASIC && !currentSubscription) {
      return priceSectionWithDiscount(basicPlanDetailsWithDiscount, BasicPlanDiscountImage)
    }

    if (show2024StandardDiscount && title?.toUpperCase() === planType.STANDARD && !currentSubscription) {
      return priceSectionWithDiscount(standardPlanDetailsWithDiscount, StandardPlanDiscountImage)
    }

    return priceSection()
  }

  return (
    <div className={styles.tableRowColumn} onClick={() => updateActiveSubscription(title)}>
      {planHeader()}

      <div
        className={classNames(styles.outlineButton, styles.button, activeSubscription === title && styles.hoverButton)}
        data-e2e={`plan-title-${title}`}
      >
        {label}
      </div>

      {renderPriceSection()}
    </div>
  )
}

SubscriptionPriceSection.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  currentSubscription: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  activeSubscription: PropTypes.string.isRequired,
  updateActiveSubscription: PropTypes.func.isRequired
}

SubscriptionPriceSection.defaultProps = {
  title: '',
  price: '',
  subTitle: '',
  currentSubscription: '',
  period: '',
  label: '',
  activeSubscription: '',
  updateActiveSubscription: null
}

export default SubscriptionPriceSection
