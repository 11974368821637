import React from 'react'
import {
  compose, defaultTo, head, indexOf, nth, pathOr, split
} from 'ramda'

import GuestHomePageContent from '../subscription-table/guest-home-page-content'

export const getPromotionalTiles = pathOr([], ['promotionalTiles'])

export const getCurrentLocation = pathOr('', ['location', 'pathname'])

const planTableRoutesNeon = ['/', '/tv', '/about-neon']

export const shouldPlanTableShown = (history, isAuthenticated) => {
  if (isAuthenticated) return false

  const location = getCurrentLocation(history)

  return indexOf(location, planTableRoutesNeon) !== -1
}

// TODO: need to update admin console to remove this hack.
export const getHeader = compose(defaultTo(''), head, split('::'))
export const getLink = compose(defaultTo(''), nth(1), split('::'))

export function getSubscriptionTable(history, isAuthenticated) {
  return shouldPlanTableShown(history, isAuthenticated) ? <GuestHomePageContent key="subscription-table" /> : null
}
