import {
  formatDistanceStrict,
  differenceInHours,
  differenceInMinutes,
  format as dateFormat
} from 'date-fns'
import {
  reduce, concat, compose, toPairs, join, last
} from 'ramda'
import { DURATION_FORMAT, RENTALS } from '../constants'

const {
  showDaysLimitInHours,
  showHoursLimitInHours,
  showMinutesLimitInMinutes
} = RENTALS

const withSuffix = (timeInWordsLeftToWatch, suffix) => {
  return suffix ? `${timeInWordsLeftToWatch} ${suffix}` : timeInWordsLeftToWatch
}

export function getTimeInWordsLeftToWatch(secondsLeftToStartWatching, suffix) {
  const now = new Date().getTime()
  const end = now + (secondsLeftToStartWatching * 1000)

  if (differenceInHours(end, now) > showDaysLimitInHours) {
    return withSuffix(formatDistanceStrict(now, end, { unit: 'day' }), suffix)
  }
  if (differenceInHours(end, now) >= showHoursLimitInHours) {
    return withSuffix(formatDistanceStrict(now, end, { unit: 'hour' }), suffix)
  }
  if (differenceInMinutes(end, now) >= showMinutesLimitInMinutes) {
    return withSuffix(formatDistanceStrict(now, end, { unit: 'minute' }), suffix)
  }
  return withSuffix('1 minute', suffix)
}

/**
 * Convert the duration from seconds to human readable format: Xhr Ym
 * @param {*} seconds - duration in seconds
 */
export const getDurationTextFromSeconds = (seconds, options) => {
  let durationText = ''
  const diffInHours = differenceInHours(seconds * 1000, 0)
  const hoursInMin = (diffInHours * 60 * 60 * 1000)
  const diffInMinutes = differenceInMinutes((seconds * 1000) - hoursInMin, 0)
  const format = options && options.format
  if (diffInHours > 0) {
    durationText += ` ${diffInHours} ${(format && format.hours) || DURATION_FORMAT.hours}`
  }

  if (diffInMinutes >= 0) {
    durationText += ` ${diffInMinutes || 1} ${(format && format.minutes) || DURATION_FORMAT.minutes}`
  }

  if (!options || !options.suffix) {
    return durationText
  }

  return `${durationText} ${options.suffix}`
}

/**
 * Convert the duration from minutes to human readable format: Xhr Ym
 * @param {*} minutes - duration in minutes
 */
export const getDurationTextFromMinutes = (minutes, ...rest) => {
  return getDurationTextFromSeconds(minutes * 60, ...rest)
}

const createHoursMinutesObjectFromMinutes = minutes => ({
  H: Math.floor(minutes / 60),
  M: minutes % 60
})

const collapseHoursMinutesPairsToString = reduce((accumulator, pair) => {
  if (last(pair)) {
    return concat(accumulator, join('', pair))
  }
  return accumulator
}, 'PT')

/**
 * Convert the duration from minutes to ISO8601 format: PTHXMY
 * @param {Number} minutes - duration in minutes
 */
export const convertMinutesToISODuration = compose(
  collapseHoursMinutesPairsToString,
  toPairs,
  createHoursMinutesObjectFromMinutes
)

/**
 * Get the duration in words for the coming soon label
 */
export const getDateInWords = date => dateFormat(date, 'd MMMM yyyy')
