import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import {
  always,
  compose,
  cond,
  either,
  prop,
  propOr,
  T,
  test,
  prepend
} from 'ramda'
import { Helmet } from 'react-helmet'
import classnames from 'classnames'
import { useSelector } from 'react-redux'

import ComponentsList from './components'
import AsyncBackToTop from './shared/back-to-top'

import SCREEN_FRAGMENT from '../../graphql/fragments/screen.gql'

import styles from './screen.css'
import { SCREEN_SIZE_BREAKPOINTS } from '../constants'
import { getLocationBaseWithHref } from '../lib/location'

export const getNeonScreenMeta = compose(cond([
  [either(test(/^\/tv$/), test(/^\/tv-series$/)), always({
    title: 'Watch your favourite TV Shows Online with NEON',
    description: 'Get unlimited access to a huge range of epic TV shows - try it free for 7 days!'
  })],
  [test(/^\/movies$/), always({
    title: 'Watch Movies Online and on the go with NEON',
    description: 'Join NEON today and get unlimited access to a huge catalogue of movies of all genres.'
  })],
  [test(/^\/rental$/), always({
    title: 'Rent Movies Online with NEON - No subscription required',
    description: 'Rent movies of all genres online with NEON, starting as low as $4.99 per movie. No subscription required.'
  })],
  [test(/^\/kids$/), always({
    title: 'Watch your favourite Kids Shows & Movies online with NEON',
    description: 'Get unlimited access to a huge range of kids’ shows - try it free for 7 days!'
  })],
  [test(/^\/about-lightbox$/), always({
    title: 'About NEON',
    description: 'Watch hit TV shows on-demand and rent blockbuster movies with NEON.'
  })],
  [test(/^\/about-neon$/), always({
    title: 'About NEON',
    description: 'Watch hit TV shows on-demand and rent blockbuster movies with NEON.'
  })],
  [test(/^\/my-list$/), always({
    title: 'My list - NEON'
  })],
  [test(/^\/my-rentals$/), always({
    title: 'My rentals - NEON'
  })],
  [T, always({})]
]))

export const screenHasMeta = compose(Boolean, prop('title'), getNeonScreenMeta)

const Screen = ({
  header,
  data,
  isContentItemOnMyList,
  addToMyList,
  removeFromMyList,
  playbackInfoMany,
  isAuthenticated,
  location,
  isMarketing,
  screenTitle,
  screenWidth
}) => {
  const mapState = useSelector(state => state)
  const { theme } = mapState
  const mounted = useRef()

  const screen = propOr({}, 'screen', data)
  const components = propOr([], 'components', screen)
  const marketComponent = prop('marketComponent', data)
  const isWhiteTheme = false

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
    } else if (data && !screen) {
      // ComponentDidUpdate
      data.refetch()
    }
  }, [screen])

  const children = [(
    <div
      key={screen.id}
      className={classnames({
        [styles.whiteTheme]: isWhiteTheme && !isMarketing,
        [styles.blackNav]: isMarketing
      })}
      style={{ paddingBottom: 42 }}
    >
      {
        header &&
        <h1 className={styles.header}>{header}</h1>
      }
      <ComponentsList
        components={components}
        marketComponent={marketComponent}
        isContentItemOnMyList={isContentItemOnMyList}
        theme={theme}
        addToMyList={addToMyList}
        removeFromMyList={removeFromMyList}
        playbackInfoMany={playbackInfoMany}
        screenTitle={screenTitle}
      />
      {
        screenWidth < SCREEN_SIZE_BREAKPOINTS.MIN_480 && <AsyncBackToTop />
      }
    </div>
  )]

  if (!screenHasMeta(location.pathname)) {
    return children
  }
  const { title, description } = getNeonScreenMeta(location.pathname)

  return prepend((
    <Helmet key={title + description}>
      <meta property="og:url" content={getLocationBaseWithHref()} />
      {description ? <meta name="description" property="og:description" content={description} /> : null}
      <meta name="title" property="og:title" content={title} />
      <title>{title}</title>
    </Helmet>
  ), children)
}

Screen.propTypes = {
  data: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.object]),
    screen: propType(SCREEN_FRAGMENT)
  }).isRequired,
  addToMyList: PropTypes.func,
  removeFromMyList: PropTypes.func,
  isContentItemOnMyList: PropTypes.func.isRequired,
  header: PropTypes.string,
  playbackInfoMany: PropTypes.oneOfType([PropTypes.object]),
  isMarketing: PropTypes.bool.isRequired,
  screenWidth: PropTypes.number.isRequired,
  screenTitle: PropTypes.string,
  isAuthenticated: PropTypes.bool.isRequired
}

Screen.defaultProps = {
  header: '',
  addToMyList: T,
  removeFromMyList: T,
  playbackInfoMany: null,
  screenTitle: ''
}

export default Screen
