/* eslint-disable react/jsx-closing-tag-location */
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import ReactSvg from 'react-svg'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'

import rightArrow from '../../../images/arrow-right.svg'

import styles from './grid-header-breadcrumb.css'
import { CategoryContext } from '../category/category-context'

const TABS_LABELS = {
  Title: 'Movies',
  Series: 'TV'
}

const BROWSE_RENTALS = 'browse-rentals'

const GridHeaderBreadcrumb = ({
  isEmptyGrid, selectedTabType, isKidTheme, header
}) => {
  if (isEmptyGrid) {
    return null
  }
  const tabTypeLabel = TABS_LABELS[selectedTabType]
  const currentLocation = useLocation()
  const includeRental = currentLocation.pathname.includes(BROWSE_RENTALS)
  const mainTitle = includeRental ? 'Rentals' : tabTypeLabel

  const selectedCategory = useContext(CategoryContext)

  return (
    <div className={classNames(styles.wrapper, {
      [styles.kidsThemeWrapper]: isKidTheme
    })}
    >
      <div className={classNames(styles.mainTitle, {
        [styles.kidsThemeTitle]: isKidTheme
      })}
      >
        {isKidTheme ? header : mainTitle}
      </div>
      {selectedCategory
        // eslint-disable-next-line react/jsx-wrap-multilines
        ? <>
          <ReactSvg
            src={rightArrow}
            className={styles.rightArrow}
          />
          <div className={classNames(styles.header, {
            [styles.rentalHeader]: includeRental
          })}
          >
            {selectedCategory.name}
          </div>
        </>
        : null}
    </div>
  )
}

GridHeaderBreadcrumb.propTypes = {
  isEmptyGrid: PropTypes.bool,
  selectedTabType: PropTypes.string,
  header: PropTypes.string,
  isKidTheme: PropTypes.bool.isRequired
}

GridHeaderBreadcrumb.defaultProps = {
  isEmptyGrid: false,
  selectedTabType: '',
  header: ''
}

export default GridHeaderBreadcrumb
