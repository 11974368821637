import { createUseStyles } from 'react-jss'
import { commonStyles } from '../../../shared/common.styles'

export const useStyles = createUseStyles(theme => ({
  ...commonStyles(theme),
  tableRowContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    [theme.media.mobile]: {
      flexDirection: 'column'
    },
    [theme.media.desktop]: {
      gap: '10px'
    },
    [theme.media.tablet]: {
      flexDirection: 'column'
    },
    [theme.media.smallMobile]: {
      flexDirection: 'column'
    },
    width: '100%'
  },

  tableRowTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 2,
    [theme.media.tablet]: {
      textAlign: 'center',
      justifyContent: 'center',
      paddingBottom: 12
    },
    [theme.media.mobile]: {
      textAlign: 'center',
      justifyContent: 'center',
      paddingBottom: 12
    },
    [theme.media.smallMobile]: {
      textAlign: 'center',
      justifyContent: 'center',
      paddingBottom: 8
    }
  },

  iconFrame: {
    padding: [0, 20, 0, 28],
    [theme.media.tablet]: {
      display: 'none'
    },
    [theme.media.mobile]: {
      display: 'none'
    },
    [theme.media.smallMobile]: {
      display: 'none'
    }
  },

  tableRowContent: {
    display: 'flex',
    flexDirection: 'row',
    flex: 3,
    fontSize: 20,
    [theme.media.smallMobile]: {
      fontSize: 16,
      marginTop: 6
    }
  },

  tableRowColumn: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },

  tableLastRowColumn: {
    justifyContent: 'flex-start',
    marginTop: '0 !important',
    paddingBottom: 16,
    fontWeight: 250,
    color: '#fff',
    fontSize: 20,
    [theme.media.tablet]: {
      paddingTop: 5,
      fontSize: 18,
      marginTop: '-5px !important'
    },
    [theme.media.mobile]: {
      paddingTop: 5,
      marginTop: '-5px !important'
    },
    [theme.media.smallMobile]: {
      paddingTop: 5,
      fontSize: 14,
      marginTop: '-5px !important'
    }
  },

  tableTitleWithExtraInfoColumn: {
    justifyContent: 'flex-start',
    fontWeight: 250,
    color: '#fff',
    fontSize: 20,
    [theme.media.tablet]: {
      paddingTop: 5,
      fontSize: 18,
      marginTop: '-5px !important'
    },
    [theme.media.mobile]: {
      paddingTop: 5,
      marginTop: '-5px !important'
    },
    [theme.media.smallMobile]: {
      paddingTop: 5,
      fontSize: 14,
      marginTop: '-5px !important'
    }
  },

  tableTitleWithExtraInfoRow: {
    [theme.media.desktop]: {
      paddingTop: 10
    }
  },

  tableTitleFrame: {
    display: 'flex',
    flexDirection: 'column'
  },

  tableRowTitleText: {
    color: '#a0a0a0',
    fontSize: 20,
    [theme.media.tablet]: {
      fontSize: 18
    },
    [theme.media.smallMobile]: {
      fontSize: 14
    }
  },

  tableRowSubTitleText: {
    color: '#a0a0a0',
    fontSize: 16,
    [theme.media.tablet]: {
      fontSize: 14
    },
    [theme.media.smallMobile]: {
      fontSize: 12
    },
    textDecoration: 'underline'
  },

  disabledColor: {
    color: '#a0a0a0',
    backgroundColor: theme.greyHighlight
  },

  marginForBasic: {
    margin: [-10, 18, -10, 0]
  },

  marginForStandard: {
    margin: [-10, 8, -10, 8]
  },

  marginForAnnual: {
    margin: [-10, 0, -10, 20]
  },

  divider: {
    height: 1,
    margin: [15, 0, 15],
    backgroundColor: theme.field,
    width: '100%'
  },

  contentItemFrame: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },

  contentItemFrameForMultipleLines: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },

  activeSubscription: {
    color: theme.neonGreen
  },

  /* Tooltip container */
  tooltip: {
    position: 'relative',
    display: 'inline-block',
    borderBottom: '1px dotted black', /* If you want dots under the hoverable text */

    '&:hover': {
      '&>$tooltiptext': {
        visibility: 'visible',
        opacity: 1
      }
    }
  },

  tooltipForMultipleLines: {
    position: 'relative',
    display: 'flex',
    borderBottom: '1px dotted black', /* If you want dots under the hoverable text */
    alignItems: 'center',
    justifyContent: 'center',

    '&:hover': {
      '&>$tooltiptext': {
        visibility: 'visible',
        opacity: 1
      }
    }
  },

  /* Tooltip text */
  tooltiptext: {
    visibility: 'hidden',
    width: 220,
    backgroundColor: '#fff',
    color: '#000',
    textAlign: 'center',
    padding: '17px 14px 15px 14px',
    borderRadius: 4,
    fontSize: 16,
    fontWeight: 325,
    lineHeight: '19.2px',

    /* Position the tooltip text */
    position: 'absolute',
    zIndex: 1,
    bottom: '125%',
    left: '50%',
    marginLeft: -95,

    /* Fade in tooltip */
    opacity: 0,
    transition: 'opacity 0.3s',

    '&::after': {
      content: "''",
      position: 'absolute',
      top: '100%',
      left: '50%',
      marginLeft: -5,
      borderWidth: 5,
      borderStyle: 'solid',
      borderColor: '#fff transparent transparent transparent'
    },

    [theme.media.mobile]: {
      fontSize: 14,
      lineHeight: '18px',
      padding: '15px 12px 13px 12px',
      width: 140,
      marginLeft: -70
    },
    [theme.media.smallMobile]: {
      fontSize: 12,
      lineHeight: '16px',
      padding: '12px 10px 10px 10px',
      width: 120,
      marginLeft: -60
    }
  },

  tooltiptextForMultipleLines: {
    marginLeft: -67,
    [theme.media.mobile]: {
      marginLeft: -27
    },
    [theme.media.smallMobile]: {
      marginLeft: -23
    }
  },

  tooltipIconFrame: {
    marginRight: -30,
    [theme.media.mobile]: {
      marginRight: 0
    },
    [theme.media.smallMobile]: {
      marginRight: 0
    }
  },

  tooltipIconForMultipleLines: {
    marginRight: -35,
    paddingLeft: 5
  },

  tooltipIcon: {
    [theme.media.smallMobile]: {
      height: 22,
      width: 22
    }
  }
}))
