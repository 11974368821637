import React from 'react'
import PropTypes from 'prop-types'
import SubscriptionPriceSection from '../subscription-price-section/subscription-price-section'
import { usePlanChangeFlag } from '../../../../hooks/usePlanChange'

const SubscriptionPricesView = props => {
  const { activeSubscription, updateActiveSubscription, currentSubscription } = props
  const getPlanDetails = usePlanChangeFlag()

  return (
    <>
      <SubscriptionPriceSection
        title={getPlanDetails?.basic.title}
        label={getPlanDetails?.basic.label}
        price={getPlanDetails?.basic.price}
        period={getPlanDetails?.basic.periodNew}
        activeSubscription={activeSubscription}
        updateActiveSubscription={updateActiveSubscription}
        currentSubscription={currentSubscription}
      />

      <SubscriptionPriceSection
        title={getPlanDetails?.standard.title}
        label={getPlanDetails?.standard.label}
        price={getPlanDetails?.standard.price}
        period={getPlanDetails?.standard.periodNew}
        activeSubscription={activeSubscription}
        updateActiveSubscription={updateActiveSubscription}
        currentSubscription={currentSubscription}
      />

      <SubscriptionPriceSection
        title={getPlanDetails?.annual.title}
        label={getPlanDetails?.annual.label}
        price={getPlanDetails?.annual.price}
        period={getPlanDetails?.annual.periodNew}
        subTitle={getPlanDetails?.annual.subContent}
        activeSubscription={activeSubscription}
        updateActiveSubscription={updateActiveSubscription}
        currentSubscription={currentSubscription}
      />
    </>
  )
}

SubscriptionPricesView.propTypes = {
  activeSubscription: PropTypes.string.isRequired,
  updateActiveSubscription: PropTypes.func.isRequired,
  currentSubscription: PropTypes.string
}

SubscriptionPricesView.defaultProps = {
  activeSubscription: '',
  updateActiveSubscription: null,
  currentSubscription: ''
}

export default SubscriptionPricesView
