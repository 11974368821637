import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'

import PROMOTIONAL_TILE_FRAGMENT from '../../../../graphql/fragments/promotional-tile-component.gql'
import styles from './two-tile-promotional-grid.css'
import {
  getPromotionalTiles,
  getSubscriptionTable
} from '../utils'
import Tile from './tile'

function TwoTilePromotionalGrid({
  promotionalGrid,
  history,
  onMouseEnter,
  onMouseLeave,
  overlayShownOn,
  isAuthenticated
}) {
  return [
    <div
      className={styles.wrapper}
      key="promotional-grid"
    >
      {
        getPromotionalTiles(promotionalGrid)
          .slice(0, 2)
          .map(({ header, image }, index) => {
            return (
              <Tile
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                history={history}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                overlayShownOn={overlayShownOn}
                index={index}
                image={image}
                className={styles.tile}
              />
            )
          })
      }
    </div>,
    getSubscriptionTable(history, isAuthenticated)
  ]
}

TwoTilePromotionalGrid.propTypes = {
  promotionalGrid: propType(PROMOTIONAL_TILE_FRAGMENT).isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  overlayShownOn: PropTypes.number.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
}

export default TwoTilePromotionalGrid
