import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ReactImgix from 'react-imgix'

import * as guestTileContent from './content'
import NavLinkWithQuery from '../../shared/nav-link-with-query'

import imgixStyles from '../../imgix/imgix.css'
import styles from './tile.css'

function Tile({
  className,
  image,
  index,
  history,
  onMouseLeave,
  onMouseEnter,
  overlayShownOn
}) {
  const content = index === 0
    ? guestTileContent.left
    : guestTileContent.right

  const isPhone = window.innerWidth < 1024

  // TODO remove the ReactImgix to use recipe instead
  return (
    <div className={styles.wrapper}>
      <div
        className={classnames(styles.imageWrapper, className)}
        onMouseEnter={() => onMouseEnter(index)}
        onMouseLeave={onMouseLeave}
      >
        <div
          className={classnames(styles.overlay, {
            [styles.showOverlay]: overlayShownOn === index
          })}
          onClick={() => history.push(content.link.url)}
        >
          <div className={styles.overlayText}>
            <NavLinkWithQuery
              className={styles.link}
              to={content.link.url}
            >
              {content.link.text}
            </NavLinkWithQuery>
            <h2 className={styles.sub}>{content.sub}</h2>
          </div>
        </div>
        <ReactImgix
          className={classnames(
            imgixStyles.imgix,
            styles.image
          )}
          src={image.uri}
          // set height and width for IE and Firefox
          width={!isPhone ? 1000 : null}
          height={isPhone ? 320 : null}
        />

        <div className={styles.meta}>
          <div className={styles.imageTitle}>{content.image}</div>
          <div className={styles.copyright}>{content.copyright}</div>
        </div>

      </div>
      <div className={styles.bannerWrapper}>
        <div className={styles.banner}>
          {content.banner.map(banner => <span key={banner}>{banner}</span>)}
        </div>
      </div>
    </div>
  )
}

Tile.propTypes = {
  index: PropTypes.number.isRequired,
  image: PropTypes.shape({
    uri: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  overlayShownOn: PropTypes.number.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  className: PropTypes.string
}

Tile.defaultProps = {
  className: ''
}

export default Tile
