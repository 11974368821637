import React, { createContext } from 'react'
import PropTypes from 'prop-types'

/**
 * Hooks for getting selected category tab
 */

export const CategoryContext = createContext(null)

const CategoryContextProvider = ({ selectedCategory, children }) => {
  return (
    <CategoryContext.Provider value={selectedCategory}>
      {children}
    </CategoryContext.Provider>
  )
}

CategoryContextProvider.propTypes = {
  selectedCategory: PropTypes.oneOfType([PropTypes.object])
}

CategoryContextProvider.defaultProps = {
  selectedCategory: null
}

export default CategoryContextProvider
