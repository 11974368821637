import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { sanitizeDom } from '../../../utils'
import { useFeatureFlag } from '../../../hooks/useFeatureFlag'
import NavLinkWithQuery from '../../shared/nav-link-with-query'
import styles from './cta-box.css'
import EmailBox from './email-box'

function CtaBox({ content, isChangeBg }) {
  const {
    line1, subLine1, line2, line2OnGuestHomePage, line3, cta, isRental, isGuestHome, subLine1ForBasicDiscount, subLine1ForStandardDiscount
  } = content

  const show2023BasicDiscount = useFeatureFlag('2023_Basic_Discount')
  const show2024StandardDiscount = useFeatureFlag('2024_Standard_Discount')
  const isShowingDiscount = show2023BasicDiscount || show2024StandardDiscount
  const line2OnGuestHomePageText = isShowingDiscount ? ' ' : line2OnGuestHomePage
  const line2Message = isChangeBg ? line2OnGuestHomePageText : line2

  return (
    <div className={classNames(styles.wrapper, isChangeBg && styles.wrapperInCenter)}>
      <div className={classNames(styles.title, isChangeBg && styles.largeTitle)}>{line1}</div>

      {!isShowingDiscount && subLine1 && isChangeBg && (
        <div
          className={styles.subTitle}
          dangerouslySetInnerHTML={{ __html: sanitizeDom(subLine1) }}
        />
      )}

      {show2023BasicDiscount && subLine1ForBasicDiscount && isChangeBg && (
        <div
          className={styles.subTitleForBasicDiscount}
          dangerouslySetInnerHTML={{ __html: subLine1ForBasicDiscount }}
        />
      )}

      {show2024StandardDiscount && subLine1ForStandardDiscount && isChangeBg && (
        <div
          className={styles.subTitleForBasicDiscount}
          dangerouslySetInnerHTML={{ __html: subLine1ForStandardDiscount }}
        />
      )}

      {line2Message && (
        <div
          className={classNames(styles.synopsis, isChangeBg && styles.description)}
          dangerouslySetInnerHTML={{ __html: sanitizeDom(line2Message) }}
        />
      )}

      {isGuestHome ? (
        <EmailBox />
      ) : (
        <div className={styles.buttonGroup}>
          {cta.text && (
            <NavLinkWithQuery
              data-e2e={
                isRental
                  ? 'cta-box-rental-slide-button'
                  : 'cta-box-slide-start-watching-button'
              }
              className={classNames(styles.button, {
                [styles.isRental]: isRental
              })}
              to={cta.url}
            >
              {cta.text}
            </NavLinkWithQuery>
          )}
          <div className={styles.wordsUnderButton}>{line3}</div>
        </div>
      )}
    </div>
  )
}

CtaBox.propTypes = {
  content: PropTypes.shape({
    line1: PropTypes.string.isRequired,
    subLine1: PropTypes.string,
    subLine1ForBasicDiscount: PropTypes.string,
    subLine1ForStandardDiscount: PropTypes.string,
    line2: PropTypes.string.isRequired,
    line2OnGuestHomePage: PropTypes.string,
    line3: PropTypes.string.isRequired,
    cta: PropTypes.shape({
      url: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    }),
    isRental: PropTypes.bool,
    isGuestHome: PropTypes.bool
  }).isRequired,
  isChangeBg: PropTypes.bool
}

export default CtaBox
