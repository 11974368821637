import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { propType } from 'graphql-anywhere'

import { NEON_SLOGAN } from '../../../constants'
import PROMOTIONAL_TILE_FRAGMENT from '../../../../graphql/fragments/promotional-tile-component.gql'
import styles from './five-tile-promotional-grid.css'

import {
  getLink,
  getPromotionalTiles,
  getHeader,
  getSubscriptionTable
} from '../utils'
import Tile from './tile'

function FiveTilePromotionalGrid({
  promotionalGrid,
  history,
  onMouseEnter,
  onMouseLeave,
  overlayShownOn,
  isAuthenticated
}) {
  const isWhiteTheme = false

  return [
    <div
      className={classNames(styles.sloganWrapper, {
        [styles.whiteTheme]: isWhiteTheme
      })}
      key="slogan"
    >
      {NEON_SLOGAN}
    </div>,
    <div
      className={classNames(styles.wrapper, {
        [styles.whiteTheme]: isWhiteTheme
      })}
      key="promotional-grid"
    >
      {
        getPromotionalTiles(promotionalGrid)
          .slice(0, 5)
          .map(({ header, image }, index) => {
            const link = getLink(header)
            const title = getHeader(header)
            return (
              <Tile
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                overlayShownOn={overlayShownOn}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                index={index}
                link={link}
                title={title}
                image={image}
                history={history}
              />
            )
          })
      }
    </div>,
    getSubscriptionTable(history, isAuthenticated)
  ]
}

FiveTilePromotionalGrid.propTypes = {
  promotionalGrid: propType(PROMOTIONAL_TILE_FRAGMENT).isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  overlayShownOn: PropTypes.number.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
}

export default FiveTilePromotionalGrid
