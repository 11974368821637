/**
* REFACTOR: This file has been lifted + shifted from /components/login and
*           styled for the dark modal theme.
**/

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { identity } from 'ramda'

import styles from './input.css'

import requiredIcon from '../../../../images/required-star.svg'
import checkIcon from '../../../../images/forms-check.svg'

function Input({
  name,
  labelClass,
  inputClass,
  warningClass,
  label,
  placeholder,
  value,
  type,
  warning,
  exclamationClass,
  checkClass,
  onChange,
  onBlur,
  readonly,
  readonlyTextOverwrite,
  readonlyClassName,
  isRequired,
  isValid,
  minLength,
  maxLength,
  pattern,
  onPaste,
  disabled,
  isValidText,
  isValidTextClassName,
  setRef,
  className,
  e2eIdentifier
}) {
  const labelClassName = labelClass || styles.label
  const warningClassName = warningClass || styles.warning
  const exclamationClassName = exclamationClass || styles.exclamation
  const checkClassName = checkClass || styles.check

  return (
    <div className={classNames(styles.wrapper, className)}>
      {
        label && (
          <label
            className={labelClassName}
            htmlFor={name}
          >
            {label}
            {isRequired && <img className={styles.required} alt="Required" src={requiredIcon} />}
          </label>
        )
      }
      {
        !readonly && (
          <input
            id={name}
            name={name}
            className={classNames({
              [styles.input]: true,
              [styles.invalid]: warning,
              [styles.valid]: isValid,
              [inputClass]: inputClass
            })}
            value={value}
            placeholder={placeholder}
            type={type}
            minLength={minLength}
            maxLength={maxLength}
            onChange={onChange}
            onBlur={onBlur}
            onPaste={onPaste}
            pattern={pattern}
            disabled={disabled}
            ref={setRef}
            data-lbx-e2e={e2eIdentifier ? `${e2eIdentifier}-input` : ''}
          />
        )
      }
      {
        (readonly && !readonlyTextOverwrite) &&
        <div className={classNames(styles.readonlyValue, readonlyClassName)}>{value}</div>
      }
      {
        (readonly && readonlyTextOverwrite) && (
          <div
            className={classNames(styles.readonlyValue, readonlyClassName)}
          >
            {readonlyTextOverwrite}
          </div>
        )
      }
      {
        warning
          ? (
            <span
              className={exclamationClassName}
              data-lbx-e2e={e2eIdentifier ? `${e2eIdentifier}-warning-exclamation` : ''}
            />
          )
          : null
      }
      {
        isValid
          ? <img alt="valid" className={checkClassName} src={checkIcon} />
          : null
      }
      {
        !readonly && (
          <span
            className={warningClassName}
            data-lbx-e2e={e2eIdentifier ? `${e2eIdentifier}-warning` : ''}
          >
            {warning}
          </span>
        )
      }
      {
        isValid && isValidText && (
          <span className={classNames(isValidTextClassName)}>
            {isValidText}
          </span>
        )
      }
    </div>
  )
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  labelClass: PropTypes.string,
  inputClass: PropTypes.string,
  warningClass: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  warning: PropTypes.string,
  exclamationClass: PropTypes.string,
  checkClass: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onPaste: PropTypes.func,
  readonly: PropTypes.bool,
  readonlyTextOverwrite: PropTypes.string,
  readonlyClassName: PropTypes.string,
  isRequired: PropTypes.bool,
  isValid: PropTypes.bool,
  minLength: PropTypes.string,
  maxLength: PropTypes.string,
  pattern: PropTypes.string,
  disabled: PropTypes.bool,
  isValidText: PropTypes.string,
  isValidTextClassName: PropTypes.string,
  setRef: PropTypes.func,
  className: PropTypes.string,
  e2eIdentifier: PropTypes.string
}

Input.defaultProps = {
  labelClass: '',
  inputClass: '',
  warningClass: '',
  checkClass: '',
  type: 'text',
  label: '',
  placeholder: '',
  value: '',
  warning: '',
  minLength: '',
  maxLength: '',
  exclamationClass: '',
  readonly: false,
  readonlyTextOverwrite: '',
  readonlyClassName: '',
  pattern: '.*',
  isRequired: false,
  isValid: false,
  onPaste: identity,
  disabled: false,
  isValidText: '',
  isValidTextClassName: '',
  setRef: identity,
  className: '',
  e2eIdentifier: ''
}

export default Input
