import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import classNames from 'classnames'
import { T, includes } from 'ramda'
import OnVisible from 'react-on-visible'

import Clamped from '../shared/clamped'
import SlipNSlide from '../../../lib/slip-n-slide'
import { getLocationBaseWithHref } from '../../lib/location'
import {
  slipnslideIsMobile,
  slipnslideCalcTileShowCount,
  slipnslideGetMargin
} from '../../lib/utils'

import { THEME_OPTIONS, STATIC_SCREENS } from '../../constants'

import CarouselTile from '../../containers/carousel/tile'
import MyRentalsTile from './tile/my-rentals'
import ContinuityTile from './tile/continuity-tile'
import CarouselEmptyMyRentals from './carousel-empty-my-rentals'
import styles from './carousel.css'

import CAROUSEL_COMPONENT_FRAGMENT from '../../../graphql/fragments/carousel-component.gql'
import { JSONLD } from '../jsonld/index'
import { createItemListLDSchema } from '../../lib/jsonld/item-list'
import { IdleQueue } from '../../lib/idlize/IdleQueue.mjs'
import { ContinuityContext } from '../../modules/continuity/hooks/continuity-context'
import { GET_ALL_TILES } from '../../modules/continuity/hooks/continuity-reducer'

const getTileType = (carousel) => {
  if (carousel.subType === STATIC_SCREENS.MY_RENTALS.subType) {
    return MyRentalsTile
  }
  if (carousel.subType === 'MY_CONTINUE_WATCHING') {
    return ContinuityTile
  }
  return CarouselTile
}

const renderTiles = (
  carousel,
  Tile,
  theme,
  carouselPosition,
  isContentItemOnMyList,
  addToMyList,
  removeFromMyList,
  playbackInfoMany,
  tiles
) => {
  return (tiles || []).map((tile, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <div key={index}>
      <Tile
        tile={tile}
        subType={carousel.subType}
        contentType={carousel.contentType}
        index={index}
        theme={theme}
        listTitle={carousel.header || ''}
        carouselPosition={carouselPosition}
        isOnMyList={isContentItemOnMyList(tile.contentItem.id)}
        addToMyList={addToMyList}
        removeFromMyList={removeFromMyList}
        playbackInfoMany={playbackInfoMany}
      />
    </div>
  ))
}

const getEmptyCarousel = (carousel, theme) => {
  return carousel.subType === STATIC_SCREENS.MY_RENTALS.subType ? (
    <CarouselEmptyMyRentals theme={theme} />
  ) : null
}

const carouselIdleQueue = new IdleQueue({
  defaultMinTaskTime: 5
})

const Carousel = React.memo(({
  carousel,
  theme,
  carouselPosition,
  isContentItemOnMyList,
  addToMyList,
  removeFromMyList,
  playbackInfoMany,
  screenWidth,
  isWhiteTheme,
  isMarketing,
  screenTitle
}) => {
  const [isVisible, setIsVisible] = useState(false)
  const setVisibility = (visible) => {
    if (!visible || visible === isVisible) {
      return
    }

    setIsVisible(true)
  }
  const [{ tiles: continuityTiles }, dispatch] = useContext(ContinuityContext)
  const { tiles } = carousel

  useEffect(() => {
    carouselIdleQueue.pushTask(() => setVisibility(true))

    // Send all continue items to ContinuityContext hook for filter purpose
    if (carousel.subType === 'MY_CONTINUE_WATCHING' && tiles) {
      // set each continue item initial undo state
      const continueTiles = tiles.map(tile => {
        const updateTile = Object.assign(tile, { isInUndoState: false })
        return updateTile
      })
      dispatch({ type: GET_ALL_TILES, tiles: continueTiles })
    }

    return () => {
      carouselIdleQueue.clearPendingTasks()
    }
  }, [])

  if (!isVisible) {
    return <OnVisible onChange={visible => setVisibility(visible)} />
  }

  const slides = renderTiles(
    carousel,
    getTileType(carousel),
    theme,
    carouselPosition,
    isContentItemOnMyList,
    addToMyList,
    removeFromMyList,
    playbackInfoMany,
    carousel.subType === 'MY_CONTINUE_WATCHING' ? continuityTiles : tiles
  )

  const emptyCarousel = getEmptyCarousel(carousel, theme)
  if ((!slides || !slides.length) && !emptyCarousel) {
    return null
  }
  const contentTypeClass =
    carousel.contentType === 'TITLE'
      ? styles.movieCarousel
      : styles.seriesCarousel

  const slidesToShow = slipnslideCalcTileShowCount(screenWidth, 2)
  const margin = slipnslideGetMargin(screenWidth)
  const isMyRentalsKids =
    carousel.subType === STATIC_SCREENS.MY_RENTALS.subType &&
    theme === THEME_OPTIONS.light

  return (
    <div
      data-lbx-e2e="logged-in-carousel"
      className={classNames(
        styles.carouselComponentWrapper,
        contentTypeClass,
        {
          [styles.whiteTheme]: isWhiteTheme || isMarketing,
          [styles.seriesYMALWrapper]: isYMALSeriesPage(carousel)
        }
      )}
    >
      {carousel.header && (
        <h2 className={styles.carouselHeader}>
          <Clamped
            lines={2}
            className={
              classNames({
                [styles.oneline]: carousel.subType === 'SEARCH'
              })
            }
          >
            {carousel.header}
          </Clamped>
          {carousel.subType === 'SEARCH' && (
            <span className={styles.headerCount}>
              (
              {carousel.tiles.length}
              )
            </span>
          )}
          {// Add line after carousel title
            carousel.subType === STATIC_SCREENS.MY_RENTALS.subType &&
            screenTitle === STATIC_SCREENS.MY_RENTALS.title && (
              <span
                className={classNames(styles.separator, {
                  [styles.kidsSeparator]: isMyRentalsKids
                })}
              />
            )
          }
        </h2>
      )}
      {slides && slides.length ? (
        <SlipNSlide
          arrowClass={theme === THEME_OPTIONS.light ? '' : styles.blackArrow}
          margin={margin}
          swipeMode={slipnslideIsMobile}
          duration={slidesToShow * 180}
          slidesToShow={slidesToShow}
        >
          {slides}
        </SlipNSlide>
      ) : emptyCarousel}
      <JSONLD
        schema={createItemListLDSchema(carousel.tiles)}
        type="ItemList"
      />
    </div>
  )
})

Carousel.propTypes = {
  carousel: propType(CAROUSEL_COMPONENT_FRAGMENT).isRequired,
  theme: PropTypes.oneOf([THEME_OPTIONS.dark, THEME_OPTIONS.light]).isRequired,
  isContentItemOnMyList: PropTypes.func.isRequired,
  carouselPosition: PropTypes.number,
  addToMyList: PropTypes.func,
  removeFromMyList: PropTypes.func,
  playbackInfoMany: PropTypes.oneOfType([PropTypes.object]),
  screenWidth: PropTypes.number.isRequired,
  isWhiteTheme: PropTypes.bool,
  isMarketing: PropTypes.bool,
  screenTitle: PropTypes.string
}

Carousel.defaultProps = {
  carouselPosition: undefined,
  addToMyList: T,
  removeFromMyList: T,
  playbackInfoMany: null,
  isWhiteTheme: false,
  isMarketing: false,
  screenTitle: null
}

export default Carousel

export const isYMALSeriesPage = (carousel) => {
  return (includes('/series/', getLocationBaseWithHref()) || includes('/movie/', getLocationBaseWithHref())) && includes('You May Also Like', carousel.header)
}
