export default {
  USER_LOGIN: 'USER_LOGIN',
  USER_LOGOUT: 'USER_LOGOUT',
  UPDATE_AUTH_TOKEN: 'UPDATE_AUTH_TOKEN',
  UPDATE_AUTH_TOKEN_AND_THEME: 'UPDATE_AUTH_TOKEN_AND_THEME',

  // Premium Introduction Actions
  SAVE_HAS_SEEN_RENEWAL_PROMPT: 'SAVE_HAS_SEEN_RENEWAL_PROMPT',
  SAVE_HAS_SEEN_REPAIR_PROMPT: 'SAVE_HAS_SEEN_REPAIR_PROMPT',
  SAVE_INTRODUCTION_VERSION: 'SAVE_INTRODUCTION_VERSION',

  GENERIC_ERROR_MSG_SHOW: 'GENERIC_ERROR_MSG_SHOW',
  GENERIC_ERROR_MSG_DISMISS: 'GENERIC_ERROR_MSG_DISMISS',

  // Video Player Actions
  SELECT_DATA_SAVER_OPTION: 'SELECT_DATA_SAVER_OPTION',
  SET_START_PLAYING_TIMESTAMP: 'SET_START_PLAYING_TIMESTAMP',
  SET_VOLUME: 'SET_VOLUME',
  SET_MUTED: 'SET_MUTED',

  // Navigation Actions
  NAVIGATION_TOGGLE_VISIBILITY: 'NAVIGATION_TOGGLE_VISIBILITY',
  SET_NAVIGATION_VISIBILITY: 'SET_NAVIGATION_VISIBILITY',
  ACCOUNT_NAVIGATION_TOGGLE_VISIBILITY: 'ACCOUNT_NAVIGATION_TOGGLE_VISIBILITY',
  SET_ACCOUNT_NAVIGATION_VISIBILITY: 'SET_ACCOUNT_NAVIGATION_VISIBILITY',
  SET_SEARCH_IS_CLICKED: 'SET_SEARCH_IS_CLICKED',
  SET_TRAILER_LAUNCHED_FROM_COMPONENT: 'SET_TRAILER_LAUNCHED_FROM_COMPONENT',

  // Loading overlay
  LOADING_OVERLAY_ENABLE: 'LOADING_OVERLAY_ENABLE',
  LOADING_OVERLAY_SUCCESS: 'LOADING_OVERLAY_SUCCESS',
  LOADING_OVERLAY_ERROR: 'LOADING_OVERLAY_ERROR',
  LOADING_OVERLAY_TIMEOUT: 'LOADING_OVERLAY_TIMEOUT',
  LOADING_OVERLAY_DISABLE: 'LOADING_OVERLAY_DISABLE',

  // Loading overlay
  PIN_REQUIRE: 'PIN_REQUIRE',
  PIN_VALIDATE: 'PIN_VALIDATE',
  PIN_DISMISS: 'PIN_DISMISS',

  // Plan upgrade popup
  PLAN_UPGRADE_POPUP_SHOW: 'PLAN_UPGRADE_POPUP_SHOW',
  PLAN_UPGRADE_POPUP_DISMISS: 'PLAN_UPGRADE_POPUP_DISMISS',
  PLAN_UPGRADE_POPUP_CONFIRM: 'PLAN_UPGRADE_POPUP_CONFIRM',

  // Themes
  THEME_SET: 'THEME_SET',

  // Service Messages
  SERVICE_MESSAGES_DISMISS: 'SERVICE_MESSAGES_DISMISS',

  // Maintenance Mode
  ENABLE_MAINTENANCE_MODE: 'ENABLE_MAINTENANCE_MODE',
  DISABLE_MAINTENANCE_MODE: 'DISABLE_MAINTENANCE_MODE',
  UPDATE_MAINTENANCE_MESSAGE: 'UPDATE_MAINTENANCE_MESSAGE',

  // Account Password Timestamp
  SET_ACCOUNT_PASSWORD_TIMESTAMP: 'SET_ACCOUNT_PASSWORD_TIMESTAMP',

  // Cast Sender Status
  CAST_SENDER_CONNECT: 'CAST_SENDER_CONNECT',
  CAST_SENDER_DISCONNECT: 'CAST_SENDER_DISCONNECT',

  // App
  SET_PLAYER_IS_IN_VIEW: 'SET_PLAYER_IS_IN_VIEW',

  // Manage Subscription Options
  SET_MANAGE_SUBS_OPTIONS: 'SET_MANAGE_SUBS_OPTIONS',

  // Current Subscription Name
  SET_CURRENT_SUBSCRIPTION_NAME: 'SET_CURRENT_SUBSCRIPTION_NAME',

  // Set Voucher Validate Error and Type
  SET_VOUCHER_VALIDATE_ERROR: 'SET_VOUCHER_VALIDATE_ERROR',
  SET_VOUCHER_VALIDATE_TYPE: 'SET_VOUCHER_VALIDATE_TYPE',

  // Set sign up form
  SET_SIGNUP_FORM_VALUE: 'SET_SIGNUP_FORM_VALUE'
}
