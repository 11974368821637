import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useHistory, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import qs from 'query-string'

import { MODALS } from '../../../../constants'
import { useStyles } from './subscription-select-button.styles'
import { productsForAvod, warnings } from '../../../change-subscription/constants'
import { usePlanChangeFlag } from '../../../../hooks/usePlanChange'

const SubscriptionSelectButtons = props => {
  const styles = useStyles()
  const history = useHistory()
  const location = useLocation()
  const [userSelectedPlan, setUserSelectedPlan] = useState(false)
  const getPlanDetails = usePlanChangeFlag()

  const {
    activeSubscription,
    selectToNext,
    showRentMovies,
    isCurrentSubscriptionSelected,
    currentSubscription,
    upgradeIsNotAvailable,
    hasRecurringVouchers,
    voucherAvailable,
    addSubscriptionFromSignup
  } = props

  useEffect(() => {
    if (userSelectedPlan && !addSubscriptionFromSignup) {
      // add subscription after user select plan which is not associated with voucher
      // Only go to next when user add voucher from my account and url has been changed
      selectToNext(activeSubscription.toUpperCase())
    }
  }, [location])

  const goBack = () => {
    history.replace({
      ...location,
      search: null
    })
  }

  const getChangeSubscriptionModal = () => {
    const basicPlanTitle = productsForAvod.basicForAvod.title
    const annualPlanTitle = productsForAvod.annualForAvod.title
    if (currentSubscription === basicPlanTitle || activeSubscription === annualPlanTitle) {
      return MODALS.qsParams.upgradeSubscription
    }

    if (currentSubscription === annualPlanTitle || activeSubscription === basicPlanTitle) {
      return MODALS.qsParams.downgradeSubscription
    }

    return null
  }

  const getChangeSubscriptionPlan = () => {
    return Object.values(productsForAvod).find(item => item.title === activeSubscription).sku
  }

  const changeSubscription = () => {
    setUserSelectedPlan(true)

    if (currentSubscription) {
      // change subscription
      // the voucher warning modal shows before payment modal when the recurringVouchers exist
      hasRecurringVouchers === 'true'
        ? history.replace({
          ...location,
          search: qs.stringify({
            modal: MODALS.qsParams.subscriptionVoucherWarn,
            nextModal: getChangeSubscriptionModal(),
            plan: getChangeSubscriptionPlan()
          })
        })
        : history.replace({
          ...location,
          search: qs.stringify({
            modal: getChangeSubscriptionModal(),
            plan: getChangeSubscriptionPlan()
          })
        })
    } else if (!voucherAvailable && !addSubscriptionFromSignup) {
      const preUrlParams = qs.parse(location.search)
      // clear voucher in url then add subscription from my account
      delete preUrlParams.voucher
      history.replace({
        ...location,
        search: qs.stringify({
          ...preUrlParams
        })
      })
    } else {
      // add subscription
      selectToNext(activeSubscription.toUpperCase())
    }
  }

  const getSubscriptionDisplayName = (planTitle) => {
    const plan = [getPlanDetails.basic, getPlanDetails.standard, getPlanDetails.annual].find(
      item => item.title === planTitle
    )

    return plan.label
  }

  const disableSelectPlanAction = isCurrentSubscriptionSelected || upgradeIsNotAvailable

  return (
    <>
      <div
        className={classNames(styles.outlineButton, styles.selectPlanButton, !showRentMovies && styles.selectPlanButtonNew, disableSelectPlanAction && styles.disabledButton)}
        onClick={() => changeSubscription()}
        data-e2e="select-subscription-confirm-button"
      >
        {`${isCurrentSubscriptionSelected ? 'Continue' : 'Select'}  ${getSubscriptionDisplayName(activeSubscription)} Plan`}
      </div>

      {disableSelectPlanAction && (
        <div
          className={classNames(styles.outlineButton, styles.selectPlanButton, styles.goBackButton)}
          onClick={goBack}
          data-e2e="select-subscription-go-back-button"
        >
          Go Back
        </div>
      )}

      {upgradeIsNotAvailable && <div className={styles.changeNotAllowedWarning}>{warnings.onlyDowngradeAllowed}</div>}
    </>

  )
}

SubscriptionSelectButtons.propTypes = {
  activeSubscription: PropTypes.string.isRequired,
  selectToNext: PropTypes.func.isRequired,
  showRentMovies: PropTypes.bool.isRequired,
  isCurrentSubscriptionSelected: PropTypes.bool.isRequired,
  currentSubscription: PropTypes.string,
  upgradeIsNotAvailable: PropTypes.bool,
  hasRecurringVouchers: PropTypes.string,
  voucherAvailable: PropTypes.bool,
  addSubscriptionFromSignup: PropTypes.bool
}

SubscriptionSelectButtons.defaultProps = {
  activeSubscription: '',
  selectToNext: null,
  showRentMovies: true,
  isCurrentSubscriptionSelected: false,
  currentSubscription: '',
  hasRecurringVouchers: null
}

export default SubscriptionSelectButtons
