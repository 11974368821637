import React from 'react'
import PropTypes from 'prop-types'
import ReactImgix from 'react-imgix'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

import styles from './banner.css'
import { dataLayerProductClick } from '../../lib/analytics/datalayer'

const transitionInterval = 4000

class Banner extends React.PureComponent {
  // eslint-disable-next-line no-undef
  state = { topImageFadeIn: true }

  componentDidMount() {
    this.timer = setInterval(() => {
      this.setState(state => ({ topImageFadeIn: !state.topImageFadeIn }))
    }, transitionInterval)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  render() {
    const { content } = this.props
    const { topImageFadeIn } = this.state

    const isPortrait = window.innerHeight / window.innerWidth > 1
    const image = isPortrait ? content.mobile : content.desktop

    const imageSharedProps = {
      type: 'img',
      width: Math.ceil(window.innerWidth / 100) * 100,
      imgixParams: { fit: 'clip' }
    }

    const onClickHandler = () => {
      dataLayerProductClick(
        {
          title: `${content.title} Banner`,
          series: {
            ldId: 0
          }
        },
        0,
        'PromotionalBanner'
      )
    }

    const getImages = () => {
      return (
        <>
          <ReactImgix
            className={classnames(styles.image, styles.topImage, {
              [styles.fadedIn]: topImageFadeIn
            })}
            src={image[0]}
            {...imageSharedProps}
          />
          <ReactImgix
            className={styles.image}
            src={image[1]}
            {...imageSharedProps}
          />
        </>
      )
    }

    // Use react router <Link> when the banner link is an internal link
    if (content.link.indexOf(location.origin) > -1) {
      const internalPathOfContentLink = content.link.replace(location.origin, '')
      return (
        <Link
          onClick={onClickHandler()}
          to={internalPathOfContentLink}
          className={styles.wrapper}
        >
          { getImages() }
        </Link>
      )
    }
    // Use <a> tag when the banner link is an external url
    return (
      <a
        className={styles.wrapper}
        href={content.link}
        onClick={() => { onClickHandler() }}
      >
        { getImages() }
      </a>
    )
  }
}

export const BannerContentType = PropTypes.shape({
  desktop: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  mobile: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
})

Banner.propTypes = {
  content: BannerContentType.isRequired
}

export default Banner
