import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { links } from '../../../modules/shared/subscription-constants'
import { STORAGE_KEY_VOUCHER_APPLICABLE_PLANS, STORAGE_KEY_SELECTED_PLAN_ON_GUEST } from '../../../storage-keys'
import PlanSelectionStandardViewNew from '../../../modules/signup/components/plan-selection-standard-view/plan-selection-standard-view-new'
import PlanSelectionMediumView from '../../../modules/signup/components/plan-selection-medium-view/plan-selection-medium-view'
import styles from './guest-home-page.css'

const GuestHomePageContent = () => {
  const history = useHistory()

  useEffect(() => {
    // Remove following keys when users access guest home page.
    sessionStorage.removeItem(STORAGE_KEY_VOUCHER_APPLICABLE_PLANS)
    sessionStorage.removeItem(STORAGE_KEY_SELECTED_PLAN_ON_GUEST)
  }, [])

  const nextStep = async (subscription) => {
    history.push({
      pathname: links.createAccount
    })
    /**
     * Save the selected plan on guest home page when users go to the create account page
     * So we can highlight this plan when users access the select plan table again
     */
    sessionStorage.setItem(STORAGE_KEY_SELECTED_PLAN_ON_GUEST, subscription)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.contentBackground}>
        <div className={styles.contentWrapper}>
          <PlanSelectionStandardViewNew selectToNext={nextStep} />
          <PlanSelectionMediumView selectToNext={nextStep} />
        </div>
      </div>
    </div>
  )
}

export default GuestHomePageContent
