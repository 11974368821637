import React from 'react'
import classnames from 'classnames'
import PT from 'prop-types'
import { compose, getContext } from 'recompose'
import { addIndex, map } from 'ramda'

import styles from './slide.css'
import { LEFT_SLIDE, RIGHT_SLIDE, STILL } from './constants'
import { buildIndexArray, buildIndexSequence } from './utils'
import withTouchHandler from './with-touch-handler'

function Slide({
  duration,
  slidesToShow,
  offset,
  children,
  sliderStatus,
  onTransitionFinish,
  margin,
  isHideLeft
}) {
  const containerWidth = `${100 / slidesToShow}%`
  const slideTransition = `${duration}ms`

  function wrapChildren(childrenToWrap, key) {
    return (
      <div
        key={key}
        className={styles.itemWrapper}
        style={{ width: containerWidth }}
      >
        {React.cloneElement(childrenToWrap, {
          className: classnames(
            styles.itemStyle,
            childrenToWrap.props.className
          )
        })}
      </div>
    )
  }

  const createSequence = buildIndexSequence(children.length)

  const mapWithIndex = addIndex(map)

  const generateElements = compose(
    mapWithIndex(wrapChildren),
    map(idx => children[idx])
  )

  const sequenceBuilderLeft = compose(
    generateElements,
    buildIndexArray(children.length, slidesToShow + 1),
    start => createSequence(start - 1)
  )

  const sequenceBuilderRight = compose(
    generateElements,
    buildIndexArray(children.length, slidesToShow + 1),
    createSequence
  )

  const sequenceBuilderCenter = compose(
    generateElements,
    buildIndexArray(children.length, slidesToShow),
    createSequence
  )

  const startingPoint = slidesToShow * offset
  const left = sequenceBuilderLeft(startingPoint - slidesToShow)
  const right = sequenceBuilderRight(startingPoint + slidesToShow)
  const center = sequenceBuilderCenter(startingPoint)

  return (
    <div
      onTransitionEnd={evt => {
        if (evt.currentTarget === evt.target) {
          onTransitionFinish()
        }
      }}
      style={{
        transitionDuration:
          sliderStatus !== STILL ? slideTransition : undefined,
        marginLeft: margin,
        marginRight: margin
      }}
      className={classnames(styles.still, styles.sliderWrapper, {
        [styles.slideLeft]: sliderStatus === LEFT_SLIDE,
        [styles.slideRight]: sliderStatus === RIGHT_SLIDE,
        [styles.disabled]: sliderStatus !== STILL
      })}
    >
      <div
        className={classnames(styles.bucket, {
          [styles.hide]: isHideLeft
        })}
      >
        <div
          className={classnames(styles.bucketEdge, styles.leftBucket)}
          style={{
            msTransform: `translateX(-${containerWidth})` // for the love of ie
          }}
        >
          {left}
        </div>
      </div>

      <div className={classnames(styles.bucket)}>
        <div className={styles.bucketEdge} data-e2e="slides-center">{center}</div>
      </div>

      <div className={classnames(styles.bucket)}>
        <div className={styles.bucketEdge}>{right}</div>
      </div>
    </div>
  )
}

Slide.propTypes = {
  sliderStatus: PT.string.isRequired,
  onTransitionFinish: PT.func.isRequired,
  slidesToShow: PT.number.isRequired,
  duration: PT.number.isRequired,
  offset: PT.number.isRequired,
  isHideLeft: PT.bool.isRequired,
  margin: PT.number
}

Slide.defaultProps = {
  margin: 0
}

const enhance = compose(
  withTouchHandler(),
  getContext({
    slidesToShow: PT.number.isRequired,
    duration: PT.number.isRequired,
    offset: PT.number.isRequired,
    isHideLeft: PT.bool.isRequired
  })
)

export default enhance(Slide)
