import { useState, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'

import FeatureFlagQuery from '../../graphql/queries/feature-flags.gql'

/**
 * Fetches the value of a feature flag from the server
 * Can be supplied with a default value to use while resolving the feature flag
 *
 * @param {String} featureName The name of the feature flag
 * @param {*} defaultValue Dictate the default value to use while the flag is loading (false by default)
 */
const useFeatureFlag = (featureName, defaultValue = false) => {
  const [flag, setFlag] = useState(defaultValue)
  const { data, loading, error } = useQuery(FeatureFlagQuery, {
    variables: {
      featureNames: [featureName]
    }
  })

  useEffect(() => {
    if (loading) return

    if (error) {
      console.error(
        `Unable to obtain value for feature flag "${featureName}" - proceeding with default value`
      )
      return
    }

    setFlag(data.featureflags[0].isEnabled)
  }, [data, loading, error])

  return flag
}

export { useFeatureFlag }
