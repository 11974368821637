import React from 'react'
import PropTypes from 'prop-types'
import {
  NavLink,
  withRouter
} from 'react-router-dom'
import {
  compose,
  mapProps
} from 'recompose'
import { omit } from 'ramda'
import { ctaClick } from '../../lib/analytics/events'

/*
*
* This component ensures that nofollow is added
* when the component is rendered anywhere other than /.
*
* This ensures that crawlers do not crawl /movies?modal=login etc.,
* instead only crawling /?modal=login
*
* */

function NavLinkWithQuery({
  isNotHome, children, analyticsLabel, ...props
}) {
  let isModalLink = false
  const isFullUrl = typeof props.to === 'string' && props.to.indexOf('http') === 0

  if (typeof props.to === 'string') {
    isModalLink = props.to.indexOf('?modal') !== -1
  } else if (typeof props.to.search === 'string') {
    isModalLink = props.to.search.indexOf('modal') !== -1
  }

  if (isFullUrl) {
    const { to, ...otherProps } = props
    return <a href={to} {...otherProps}>{children}</a>
  }

  const captureCtaClick = () => {
    if (analyticsLabel) ctaClick(analyticsLabel)
  }

  const isPlayButton = !!props.isPlayButton
  delete props.isPlayButton

  if (isPlayButton) {
    return (
      <NavLink
        {...props}
        rel={isNotHome && isModalLink ? 'nofollow' : undefined}
      >
        {children}
      </NavLink>
    )
  }
  return (
    <NavLink
      {...props}
      onClick={captureCtaClick}
      rel={isNotHome && isModalLink ? 'nofollow' : undefined}
    >
      {children}
    </NavLink>
  )
}

NavLinkWithQuery.propTypes = {
  isNotHome: PropTypes.bool.isRequired,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      search: PropTypes.string.isRequired
    }),
    PropTypes.shape({
      state: PropTypes.shape({
        skipIntro: PropTypes.bool,
        from: PropTypes.string
      })
    })
  ]),
  analyticsLabel: PropTypes.string,
  isPlayButton: PropTypes.bool
}

NavLinkWithQuery.defaultProps = {
  to: ''
}

const enhance = compose(
  withRouter,
  mapProps(({ location, ...props }) => {
    return {
      isNotHome: Boolean(
        location &&
          location.pathname &&
          typeof location.pathname === 'string' &&
          location.pathname.split('?')[0] !== '/'
      ),
      ...omit(['history', 'staticContext', 'match'])(props)
    }
  })
)

export default enhance(NavLinkWithQuery)
