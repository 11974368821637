import React from 'react'
import PropTypes from 'prop-types'

import Form from '../../forms/layout-form'
import PasswordInput from './password-input'
import Button from '../../forms/button'

import styles from './enter-account-password.css'

const PasswordInputForm = ({
  handleSubmit,
  handleCancel,
  handleBlur,
  handleChange,
  handleIForgotMyPassword,
  isSubmitting,
  value,
  passwordValidated
}) => {
  return (
    <Form
      isSubmitting={isSubmitting}
      handleSubmit={handleSubmit}
      formClassName={styles.form}
    >
      <div>
        <PasswordInput
          value={value}
          handleChange={handleChange}
          handleBlur={handleBlur}
          passwordValidated={passwordValidated}
        />
        <div
          className={styles.iForgotMyPassword}
          onClick={handleIForgotMyPassword}
        >
          I forgot my password
        </div>
      </div>
      <div className={styles.buttons}>
        <Button
          wrapperClassName={styles.buttonWrapper}
          className={styles.button}
          text="OK"
          disabled={isSubmitting}
          data-e2e="OK-button"
        />
        <Button
          data-e2e="cancel-button"
          wrapperClassName={styles.buttonWrapper}
          className={styles.button}
          text="Cancel"
          disabled={isSubmitting}
          secondary
          onClick={handleCancel}
        />
      </div>
    </Form>
  )
}

PasswordInputForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleIForgotMyPassword: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  value: PropTypes.string,
  passwordValidated: PropTypes.bool
}

PasswordInputForm.defaultProps = {
  value: '',
  passwordValidated: null
}

export default PasswordInputForm
