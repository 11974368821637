import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'
import {
  compose, defaultTo, find, map, propEq, propOr
} from 'ramda'
import { navSectionPropType } from './common-proptypes'
import NavigationItem from '../../containers/navigation/navigation-item'
import NavigationBurgerIcon from '../../containers/navigation/navigation-burger-icon'
import NavigationRight from '../../containers/navigation/navigation-right'
import KidsLogo from './kids-logo'
import styles from './navigation-header.css'
import { navigationBarGa } from '../../lib/analytics/ga'
import { customDimensions } from '../../lib/analytics/custom-dimensions'
import { addKidsLinkToAdultProfile } from '../../lib/navigation-links'
import { getSelectedProfileIdFromSession } from '../../lib/account'
import useSearchParams from '../hooks/useSearchParams'
import { UseNotFoundContext } from '../../components/not-found/hooks/use-not-found-context'

const NavigationHeader = ({
  sections,
  isMyAccountLayout,
  isAuthenticated
}) => {
  const mapState = useSelector(state => state)
  const { session } = mapState
  const isKid = session.decodedJwt.isKid || false
  const profileId = getSelectedProfileIdFromSession(session)

  const [{ isNotFoundRendered }] = useContext(UseNotFoundContext)

  const mainSection = find(
    propEq('name', 'header.main'),
    defaultTo([], sections)
  )
  const mainSectionChildren = compose(
    propOr([], 'children'),
    defaultTo({})
  )(mainSection)

  const mainSectionLinks = addKidsLinkToAdultProfile(
    isAuthenticated,
    isKid,
    mainSectionChildren
  )

  // check current location is match main category
  const { hasSearchParams } = useSearchParams()

  const currentLocation = useLocation()
  const isActiveLink = (navItem) => {
    const BROWSE_TV = '/tv-series'
    const BROWSE_MOVIES = '/browse-movies'
    const BROWSE_RENTALS = '/browse-rentals'

    return (currentLocation.pathname.includes(BROWSE_TV) && navItem.path === '/tv') ||
      (currentLocation.pathname.includes(BROWSE_MOVIES) && navItem.path === '/movies') ||
      (currentLocation.pathname.includes(BROWSE_RENTALS) && navItem.path === '/rental') ||
      (currentLocation.pathname === navItem.path)
  }

  return (
    <nav
      data-e2e="nav nav-header"
      className={classNames(styles.nav, {
        [styles.fixedNav]: (isMyAccountLayout && !hasSearchParams) || isNotFoundRendered
      })}
    >
      <NavigationBurgerIcon display={false} />
      <NavLink
        exact
        to="/"
        data-e2e={
          isAuthenticated
            ? 'nav-logged-in-logo'
            : 'nav-guest-logo'
        }
        onClick={() => {
          navigationBarGa({
            action: 'Logo',
            [customDimensions.ProfileId]: profileId
          })
        }}
        className={classNames(styles.navLink, styles.navLinkLogo)}
      />
      {isKid && (
        <KidsLogo
          onClick={() => {
            navigationBarGa({
              action: 'Logo',
              [customDimensions.ProfileId]: profileId
            })
          }}
        />
      )}
      {}
      {map(navItem => {
        if (navItem.isDiscoverNav) {
          return null
        }
        const isActive = isKid ? false : isActiveLink(navItem)
        return (
          <NavigationItem
            key={`${navItem.name}${navItem.path}`}
            navItem={navItem}
            className={classNames(styles.navLink, {
              [styles.isRental]: navItem.isRental
            })}
            activeClassName={classNames({ [styles.navLinkActive]: isActive })}
            isInsideNavigationBar
            expandOnHover
          />
        )
      }, mainSectionLinks)}
      <NavigationRight className={styles.navRight} />
    </nav>
  )
}

NavigationHeader.propTypes = {
  sections: navSectionPropType.isRequired,
  isMyAccountLayout: PropTypes.bool,
  isAuthenticated: PropTypes.bool.isRequired
}

NavigationHeader.defaultProps = {
  isMyAccountLayout: false
}

export default NavigationHeader
