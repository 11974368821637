
const right = {
  sub: 'Monthly TV plans',
  link: {
    text: 'Start Watching',
    url: `/signup`
  },
  banner: [
    'Cancel anytime',
    'Download & Go'
  ],
  copyright: 'Vikings “V” logo © 2013 A&E.© 2019 MGM. VIKINGS™ TM Prod. Ltd. An Ireland-Canada Co-Production. All Rights Reserved.',
  image: 'Vikings'
}

const left = {
  sub: 'Rent Movies',
  link: {
    text: 'Browse',
    url: '/movies'
  },
  banner: [
    'No contract',
    '$7.99'
  ],
  copyright: '© 2019 Visiona Romantica, Inc. All Rights Reserved.',
  image: 'Once Upon A Time In Hollywood'
}

export {
  left,
  right
}
