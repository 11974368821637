import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { indexOf } from 'ramda'
import { Background } from 'react-imgix'

import imgixStyles from '../../imgix/imgix.css'
import styles from './tile.css'

function Tile({
  link,
  image,
  title,
  index,
  onMouseLeave,
  onMouseEnter,
  overlayShownOn,
  history
}) {
  // TODO remove the ReactImgix to use recipe instead
  return (
    <div
      className={styles.tile}
      onMouseEnter={() => onMouseEnter(index)}
      onMouseLeave={onMouseLeave}
    >
      <div
        className={classnames(styles.overlay, {
          [styles.showOverlay]: overlayShownOn === index
        })}
        onClick={() => history.push(link)}
      >
        <div className={styles.overlayTitle}>
          {title}
        </div>
      </div>
      <Background
        className={classnames(imgixStyles.imgix, styles.image)}
        src={image.uri}
        // set height and width for IE and Firefox
        height={index === 0 ? 764 : 382}
        width={indexOf(index, [0, 1, 4]) >= 0 ? 700 : 600}
      />
    </div>
  )
}

Tile.propTypes = {
  link: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  image: PropTypes.shape({
    uri: PropTypes.string.isRequired
  }).isRequired,
  title: PropTypes.string.isRequired,
  overlayShownOn: PropTypes.number.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
}

export default Tile
