import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  branch,
  compose,
  withStateHandlers,
  renderComponent,
  renderNothing
} from 'recompose'

import {
  FiveGridComponent,
  TwoGridComponent
} from '../../components/promotional-grid'
import { checkIsAuthenticated } from '../../lib/auth'

const enhance = compose(
  withRouter,
  connect(state => ({
    isAuthenticated: checkIsAuthenticated(state)
  })),
  withStateHandlers(
    { overlayShownOn: -1 },
    {
      onMouseEnter: () => (index) => {
        return ({ overlayShownOn: index })
      },
      onMouseLeave: () => () => ({ overlayShownOn: -1 })
    }
  ),
  branch(
    ({ promotionalGrid }) => promotionalGrid.promotionalTiles.length === 2,
    renderComponent(TwoGridComponent),
    branch(
      ({ promotionalGrid }) => promotionalGrid.promotionalTiles.length === 5,
      renderComponent(FiveGridComponent),
      renderNothing
    )
  )
)

export default enhance()
