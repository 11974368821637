// TODO: This component should be refactored to coalesce with series/title detail components
// Would also be ideal to refactor the container->comp props to avoid unnecessary re-renders
// (i.e. Try and avoid passing objects and arrays down directly)
import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import classnames from 'classnames'

import { Background } from 'react-imgix'

import ContentVideoDisplay from '../../../containers/shared/content-video-display/index'
import UserContentCTABox from './user-content-cta-box'

import HOTSPOT_SLIDE_FRAGMENT from '../../../../graphql/fragments/hotspot-slide.gql'
import CONTENT_ITEM_LIGHT_FRAGMENT from '../../../../graphql/fragments/content-item-light.gql'

import { IMGIX_CROP_OPTIONS } from '../../../constants'

import rentalBadge from '../../../../images/neon/rental-badge.png'
import styles from './slide.css'

export function UserSlide({
  className,
  isAuthenticated,
  isKid,
  myList,
  onCtaClick,
  slide,
  usableOnClick,
  playbackInfoMany
}) {
  const title = slide.header || ''

  return (
    <div
      className={classnames(styles.slideWrapper, className)}
    >
      { slide.contentItem.isRental && <img className={styles.rentalBadge} src={rentalBadge} alt="Rental Content" /> }

      <div className={styles.imageWrapper}>
        <Background
          className={styles.image}
          type="img"
          src={slide.image}
          imgixParams={{ crop: IMGIX_CROP_OPTIONS.HOTSPOT, fit: 'min' }}
          precision={50}
        />
      </div>
      {
        isKid && slide.tile ? (
          <ContentVideoDisplay
            tile={slide.tile}
            contentItem={slide.tile.contentItem}
            playbackInfoMany={playbackInfoMany}
          />
        ) : (
          <UserContentCTABox
            slide={slide}
            title={title}
            isKid={isKid}
            onCtaClick={onCtaClick}
            usableOnClick={usableOnClick}
            myList={myList}
            isAuthenticated={isAuthenticated}
            isRental={slide.contentItem.isRental}
          />
        )
      }
      <p className={styles.copyright}>
        {slide.copyright}
      </p>

    </div>
  )
}

UserSlide.propTypes = {
  slide: propType(HOTSPOT_SLIDE_FRAGMENT).isRequired,
  onCtaClick: PropTypes.func.isRequired,
  myList: PropTypes.arrayOf(propType(CONTENT_ITEM_LIGHT_FRAGMENT)).isRequired,
  usableOnClick: PropTypes.func.isRequired,
  isKid: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  className: PropTypes.string,
  playbackInfoMany: PropTypes.oneOfType([PropTypes.object])
}

UserSlide.defaultProps = {
  screenWidth: window.innerWidth,
  className: null,
  playbackInfoMany: null
}
