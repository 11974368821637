// NOTE: Due to the nature of the current reuseable form input,
// resorting to a custom approach in order to satisfy the
// requirements for the UI treatment in LBXW-1128 and to avoid any regressions
import React from 'react'
import PropTypes from 'prop-types'
import ReactSVG from 'react-svg'
import classNames from 'classnames'

import styles from './enter-account-password.css'

import exclamationIcon from '../../../../images/exclamation.svg'
import checkIcon from '../../../../images/forms-check.svg'

const CheckMark = () => {
  return (
    <span className={styles.validationIcon}>
      <ReactSVG
        src={checkIcon}
        beforeInjection={(svg) => {
          svg.setAttribute('class', styles.wrapper)
        }}
      />
    </span>
  )
}

const ExclamationMark = () => {
  return (
    <span className={styles.validationIcon}>
      <ReactSVG
        src={exclamationIcon}
        beforeInjection={(svg) => {
          svg.setAttribute('class', styles.wrapper)
        }}
      />
    </span>
  )
}

const PasswordInput = ({
  value,
  passwordValidated,
  handleBlur,
  handleChange
}) => {
  return (
    <div className={styles.passwordInput}>
      <label
        htmlFor="password"
        className={styles.label}
      >
        Password:
      </label>
      <div className={styles.inputIconWrapper}>
        <input
          name="password"
          type="password"
          className={classNames({
            [styles.input]: true,
            [styles.validated]: passwordValidated,
            [styles.invalidated]: passwordValidated === false
          })}
          value={value}
          onBlur={handleBlur}
          onChange={handleChange}
          data-e2e="account-password-input"
        />
        {
          passwordValidated && <CheckMark />
        }
        {
          (passwordValidated === false) && <ExclamationMark />
        }
      </div>
      {
        passwordValidated &&
        <span className={styles.thanks}>Thanks!</span>
      }
      {
        (passwordValidated === false) &&
        <span className={styles.sorry}>Sorry! We don&apos;t recognise this password.</span>

      }
    </div>
  )
}

PasswordInput.propTypes = {
  value: PropTypes.string,
  passwordValidated: PropTypes.bool,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired
}

PasswordInput.defaultProps = {
  value: '',
  passwordValidated: null
}

export default PasswordInput
